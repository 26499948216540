import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <p>零六零七-在吗AI-管理站</p>
            </header>

            <div className="login-container">
                <input className="input-field" type="text" placeholder="用户名"/>
                <input className="input-field" type="password" placeholder="密码"/>
                <button className="login-button">登录</button>
            </div>

            <div className="icpContainer">
                <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
                    粤ICP备2023029729号
                </a>
            </div>
        </div>
    );
}

export default App;
